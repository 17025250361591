* {
    box-sizing: border-box;
}
hr {
    width:125px;
    float:left;
}
body {
    margin: 0;
    font-family: "Helvetica",arial,serif;
    line-height: 1.7em;
    font-size:0.75em;
    letter-spacing: 0.04em;
    font-weight:400;
    background-color: whitesmoke;
}

.category-title{
    padding-left:25px;
    color:rgb(182, 182, 182);
    text-align: center;
    font-style: italic;
    text-decoration: underline;
}
.header {
    display: flex;
    align-items: center;
    border-bottom:1px solid #cccccc;
    height: 90px;
    padding: 30px 25px;
}
.header--logo-title  {
    text-decoration: none;
    color:#000;
    cursor: pointer;
}

.images--container {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    margin:75px auto;
    max-width: 1200px;
    gap: 50px;
    padding:0 5px;
}

.images { 
    max-height: 550px;
    padding-bottom:25px;
}

.about--container {
    display: flex;
    flex-direction:column;
    max-width: 900px;
    margin:0 auto;
    
}
.about-columns {
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding:5px;
    margin-top:-5px;
    margin-bottom:50px;
    color:rgb(94, 94, 94)
    
}

.about-title {
    font-size:2em;
    padding-left:5px;
}

.about-text1 {
    /* width:100%; */
    /* white-space: pre; */
}

.exhibition--container {
    margin:0 auto;
    display: flex;
    max-width:800px;
    flex-direction: column;
}

.exhibition-title {
    font-size:14px;
}
.exhibition-text {
    margin-top:-15px;
    margin-bottom:0;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90px;
    padding: 30px 25px;
}

.footer-contact {
    text-decoration: none;
    color:#000;
    font-style:italic;
}

.footer-contact:hover{
    text-decoration: underline;
    color:rgb(16, 6, 34);
    cursor: pointer;
    font-style:normal;
}

@media only screen and (max-width: 800px) {
    .square-image .images{ 
        max-height: 350px;
    }
    .landscape-image .images {
        max-width:300px;
    }

    .about-columns {
        display: flex;
        flex-direction: column;
        padding:0 10px ;
        gap:0;
        /* gap: 50px;
        margin-top:-5px;
        margin-bottom:75px;
        color:rgb(94, 94, 94) */
        
    }

    .exhibition--container {
        padding:0 10px;
    }
    
  }